import * as React from "react"
import FourCols from "../../components/FourCols"
import Hero from "../../components/Hero"
import Layout from "../../components/Layout"
import Navigation from "../../components/Navigation"
import TwoCols from "../../components/TwoCols"
import mainImage from "../../images/main-image.png"
import roadmap1 from "../../images/roadmap1.png"
import roadmap2 from "../../images/roadmap2.png"
import roadmap3 from "../../images/roadmap3.png"
import teamMember from "../../images/team.png"
import faqMan from "../../images/faq.png"
import leftArrow from "../../images/arrow-left.png"
import rightArrow from "../../images/arrow-right.png"
import line1 from "../../images/line1.png"
import line2 from "../../images/line2.png"
import line3 from "../../images/line3.png"
import heroGif from "../../images/hero-gif.gif"
import "../../css/style.css"
import "../../css/utils.css"
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"
import FAQs from "../../components/FAQs"
import DiamondHands from "../../components/DiamondHands"
import Mint from "../../components/Mint"
const MintPage = () => (
<Layout>
    

  <Mint 
   title="Mint"
   subtitle="Priority Paperlist"
   text="Click Mint below to mint your PaperHand. <strong>Mint 3 or more for a $PPR bonus</strong> ONLY available during this minting process. <br /><br />You MUST automatically stake your PaperHands during the minting process to receive this bonus. You will also be saving gas on your transaction."
   button="Mint now"
   image={heroGif}
   alt="Paperhands"
   class="jumbo-section hero full-width  mobile-pt-13 minting"/>
</Layout>
)

export default MintPage
